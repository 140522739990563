import "./WhatsAppButton.css";
import { FaWhatsapp } from "react-icons/fa";

export default function WhatsAppButton() {
  const openWhatsApp = () => {
    const url =
      "https://wa.me/message/5KFZKQ5EFRLAG1";
    window.open(url, "_blank", "noopener,noreferrer,width=900,height=600");
  };

  return (
    <a onClick={openWhatsApp} className="whatsapp-button">
      <FaWhatsapp style={{ color: "#fff", fontSize: "40px" }} />
    </a>
  );
}
